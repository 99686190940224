<template>
  <div class="data-upload">
    <el-button type="primary" @click="visible = true">订单导入</el-button>
    <el-dialog
      :visible.sync="visible"
      center
      class="upload-dialog"
      title="数据批量导入"
      width="80%"
    >
      <el-steps :active="active" align-center finish-status="success">
        <el-step description="根据模板修改后上传" title="上传文件" />
        <el-step description="预览并检查数据" title="预览数据" />
        <el-step description="数据导入至服务器" title="执行导入" />
        <el-step description="完成数据批量导入" title="导入成功" />
      </el-steps>
      <main class="upload-order-content">
        <section v-show="active === 0 && !isError" class="import-orders">
          <div class="download-template">
            <h3 class="my-2">填写导入订单的信息</h3>
            <small>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除，单次导入的数据不超过1000条。</small>
            <el-link
              :href="downloadTemplate"
              class="mt-2"
              target="_blank"
              type="primary"
            >下载模板</el-link>
          </div>
          <el-divider />
          <div>
            <h3 class="my-2">上传填好的订单信息表</h3>
            <el-upload
              ref="upload-order"
              :action="action"
              :before-upload="beforeUpload"
              :headers="uploadHeaders"
              :http-request="uploadOrder"
              :limit="1"
              :on-error="handleError"
              :on-progress="handleProgress"
              :on-remove="handleRemoved"
              :on-success="handleSuccess"
              accept=".xlsx,.xls"
              class="upload-order"
            >
              <div slot="tip" class="el-upload__tip">
                文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于50M
              </div>
              <el-button type="text">上传文件</el-button>
            </el-upload>
          </div>
        </section>
        <section v-show="active === 0 && isError" class="import-orders">
          <div class="upload-error">
            <div class="upload-error--item">
              <span class="my-2">正常数量条数：</span>
              <el-link
                :underline="false"
                class="success-account"
                type="success"
              >
                {{ errorResult.successAccount || 0 }} 条
              </el-link>
            </div>
            <div class="upload-error--item">
              <span class="my-2">异常数量条数：</span>
              <el-link
                :underline="false"
                class="error-account mr-2"
                type="danger"
              >
                {{ errorResult.errorAccount || 0 }} 条
              </el-link>
              <el-link
                type="primary"
                @click="downeErrorFile(errorResult.errorFileUrl)"
              >下载异常数据重新导入</el-link>
            </div>
          </div>
          <el-divider />
        </section>
        <section v-if="active === 1" class="data-review">
          <vxe-table
            ref="xTable"
            :data="tableData"
            align="center"
            class="vxeTable"
            highlight-hover-row
            keep-source
            max-height="600"
            show-overflow
            size="mini"
          >
            <vxe-table-column field="platform" title="平台" />
            <vxe-table-column field="site" title="站点" />
            <vxe-table-column field="shop" title="店铺" />
            <vxe-table-column field="temuSkuId" title="Temu SKU ID" />
            <vxe-table-column field="omsSku" title="发货 SKU" />
          </vxe-table>
        </section>
        <section v-show="active === 2" class="data-review">
          <el-progress
            :percentage="percentage"
            :stroke-width="26"
            :text-inside="true"
          />
          <p class="ma-5" style="text-align: center">
            正在导入数据，请勿关闭或刷新页面
          </p>
        </section>

        <section v-show="active === 4" class="data-review">
          <el-result
            :sub-title="importSuccessfulText"
            icon="success"
            title="成功"
          >
            <template slot="extra">
              <el-button
                size="medium"
                type="primary"
                @click="currentStatus('uploadFile')"
              >返回</el-button>
            </template>
          </el-result>
        </section>
      </main>

      <span slot="footer">
        <el-button
          v-show="active === 0 && !isError"
          :disabled="!isUpload"
          type="primary"
          @click="handleToReview"
        >{{ uploadLoading ? "数据校验中，请稍等" : "下一步" }}</el-button>
        <template v-if="active === 0 && isError">
          <el-button
            plain
            type="primary"
            @click="handerResetUpload"
          >重新上传</el-button>
          <el-button
            :disabled="continueImportingDisabled"
            type="primary"
            @click="handleContinueImporting"
          >继续导入正常数据</el-button>
        </template>
        <template v-if="active === 1">
          <el-button
            plain
            type="primary"
            @click="handerResetUpload"
          >重新上传</el-button>
          <el-button type="primary" @click="handleToImport">下一步</el-button>
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { temuSkuImportExcel, temuSkuSureImportExcel } from '@/api/omsorder'
import { omit } from 'lodash'

export default {
  name: 'UploadTemuSku',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      active: 0,
      tableData: [],
      isError: false,
      errorResult: {},
      checkResult: false,
      downloadTemplate: 'https://aliyun-8841886248088496.oss-cn-hongkong.aliyuncs.com/api-download/Temu%20SKU%20ID%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      action: process.env.VUE_APP_CENTER_API + '/oms/temu-sku/importExcel',
      percentage: 0,
      importSuccessfulText: '',
      uploadLoading: false
    }
  },
  computed: {
    uploadHeaders() {
      return {
        authorization: this.$store.getters.authorization,
        'x-userinfo-header': `${this.$store.getters.tenantId}-${
          this.$store.getters.userId
        }-${this.$store.getters.name}-${this.$store.getters.warehouse || 1}`
      }
    },
    isUpload() {
      return this.tableData.length !== 0 || this.errorResult.errorFileUrl
    },
    continueImportingDisabled() {
      return (
        Number.isFinite(this.errorResult.successAccount) &&
        this.errorResult.successAccount === 0
      )
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$nextTick(() => {
          this.currentStatus('uploadFile')
        })
      }
    }
  },
  methods: {
    downeErrorFile(url) {
      if (url.startsWith('http://')) {
        url = url.replace('http://', 'https://')
      }
      window.open(url, '_blank')
    },
    async uploadOrder({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      this.uploadLoading = true
      return temuSkuImportExcel(formData)
    },
    beforeUpload(file) {
      const fileArr = file.name.split('.')
      const isExcel =
        fileArr[fileArr.length - 1] === 'xlsx' ||
        fileArr[fileArr.length - 1] === 'xls'
      const isSize = file.size / 1024 / 1024 < 50
      if (!isExcel) {
        this.$message.error(this.$t('page.OnlyXlsAndXlsxFilesCanBeUploaded'))
      }
      if (!isSize) {
        this.$message.error('上传文件大小不能超过50MB')
      }
      return isExcel && isSize
    },
    handleError(err) {
      this.uploadLoading = false
      this.$message.error(JSON.parse(err.msg))
    },
    handleRemoved() {
      this.uploadLoading = false
      this.currentStatus('uploadFile')
    },
    handleSuccess(response) {
      this.uploadLoading = false
      const { code, msg } = response
      if (code === 1) {
        this.$message.error(msg)
        this.currentStatus('uploadFile')
        return
      }
      const {
        datas: { errorResult, checkResult, successResult }
      } = response
      this.checkResult = checkResult
      if (!checkResult) {
        this.errorResult = errorResult
      }
      if (successResult) {
        const { importDateList } = successResult
        this.tableData = importDateList
      }
    },
    handleContinueImporting() {
      this.currentStatus('dataPreview')
    },
    async handleToImport() {
      try {
        this.currentStatus('performImport')
        const { datas } = await temuSkuSureImportExcel(
          { requestList: this.tableData },
          (progressEvent) => {
            const complete =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0
            this.percentage = complete
          }
        )
        this.importSuccessfulText = datas
        this.currentStatus('importSuccessful')
      } catch (err) {
        this.currentStatus('dataPreview')
      }
    },
    handleToReview() {
      if (this.checkResult) {
        this.currentStatus('dataPreview')
      } else {
        this.currentStatus('dataExceptions')
      }
    },
    handerResetUpload() {
      this.currentStatus('uploadFile')
    },
    handleProgress(event) {
      this.uploadLoading = true
    },
    /**
     * @description 根据传入状态跳转步骤
     * @param {String} status uploadFile 上传文件
     * @param {String} status dataPreview 数据预览
     * @param {String} status dataExceptions 数据异常
     * @param {String} status performImport 执行导入
     * @param {String} status importSuccessful 导入成功
     */
    currentStatus(status) {
      if (status === 'uploadFile') {
        Object.assign(this.$data, omit(this.$options.data(), ['visible']))
        this.$refs['upload-order'].clearFiles()
      }
      if (status === 'dataPreview') {
        this.active = 1
        this.isError = false
        this.checkResult = true
      }
      if (status === 'dataExceptions') {
        this.active = 0
        this.isError = true
        this.checkResult = false
      }
      if (status === 'performImport') {
        this.active = 2
        this.percentage = 0
      }
      if (status === 'importSuccessful') {
        this.active = 4
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-dialog /deep/ {
  .el-step__title.is-success,
  .el-step__head.is-success,
  .el-step__description.is-success,
  .el-step__title.is-process,
  .el-step__head.is-process,
  .el-step__description.is-process {
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0px;
  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /deep/ {
    .upload-error--item {
      display: flex;
      align-items: center;
    }
    .upload-order {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .el-upload--text {
        order: 2;
      }
      .el-upload__tip {
        order: 1;
      }
      .el-upload-list.el-upload-list--text {
        order: 3;
        // .el-upload-list__item{
        //     margin-top: 0;
        //     border: 1px #1890ff dashed;
        //     background: #61b3ff;
        //     color: #fff;
        // }
        // .el-upload-list__item-name,.el-icon-document,.el-icon-close {
        //   color: #fff;
        // }
      }
    }
  }
}
</style>

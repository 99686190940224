import request from '@/utils/oms-request'

export function getList(data, signal) {
  return request({
    url: 'oms/temu-sku/page',
    method: 'post',
    data,
    signal
  })
}

export function deleteRecord(data) {
  return request({
    url: 'oms/temu-sku/delete',
    method: 'post',
    data
  })
}

export function addRecord(data) {
  return request({
    url: 'oms/temu-sku/add',
    method: 'post',
    data
  })
}

export function updateRecord(data) {
  return request({
    url: 'oms/temu-sku/update',
    method: 'post',
    data
  })
}

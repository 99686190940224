<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visible"
    top="5vh"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item label="平台/站点/店铺" prop="platformSiteShop">
        <el-cascader
          v-model="form.platformSiteShop"
          :options="platformSiteShopOptions"
          :props="{ value: 'label' }"
          clearable
          collapse-tags
          collapse-tags-tooltip
          filterable
          placeholder="请选择"
        />
      </el-form-item>
      <el-form-item label="Temu SKU ID" prop="temuSkuId">
        <el-input v-model="form.temuSkuId" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="SKU" prop="omsSku">
        <el-input v-model="form.omsSku" clearable placeholder="请输入" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button :loading="submitLoading" @click="handleClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addRecord, updateRecord } from './api'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    platformSiteShopOptions: {
      type: Array,
      default: () => []
    },
    currentRow: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        temuSkuId: '',
        omsSku: ''
      },
      rules: {
        platformSiteShop: [
          { required: true, message: '请选择平台/站点/店铺', trigger: 'change' }
        ],
        temuSkuId: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        omsSku: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      submitLoading: false
    }
  },
  computed: {
    isUpdate() {
      return !!this.currentRow
    },
    title() {
      if (this.isUpdate) {
        return '更新'
      }
      return '新增'
    },
    submitParams() {
      const [platform, site, shop] = this.form.platformSiteShop
      return {
        ...this.form,
        platform,
        site,
        shop
      }
    },
    submitFn() {
      if (this.isUpdate) {
        return updateRecord
      }
      return addRecord
    }
  },
  watch: {
    visible(val) {
      if (val && this.currentRow) {
        this.form.platformSiteShop = [this.currentRow.platform, this.currentRow.site, this.currentRow.shop]
        this.form.id = this.currentRow.id
        this.form.omsSku = this.currentRow.omsSku
        this.form.temuSkuId = this.currentRow.temuSkuId
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          this.submitLoading = true
          const { msg } = await this.submitFn(this.submitParams).finally(() => {
            this.submitLoading = false
          })
          this.$message.success(msg)
          this.$emit('refresh')
          this.handleClose()
        }
      })
    },
    handleClose() {
      this.form = {
        temuSkuId: '',
        omsSku: ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

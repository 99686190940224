<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" inline label-width="auto">
      <el-form-item label="平台/站点/店铺" prop="channels">
        <el-cascader
          v-model="form.channels"
          :options="platformSiteShopOptions"
          :props="{ value: 'label', multiple: true }"
          clearable
          collapse-tags
          collapse-tags-tooltip
          filterable
          placeholder="请选择"
        />
      </el-form-item>
      <el-form-item label="Temu SKU ID">
        <el-input v-model="form.temuSkuId" clearable placeholder="请输入" @keyup.enter="handleQuery" />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input v-model="form.omsSku" clearable placeholder="请输入" @keyup.enter="handleQuery" />
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">查询</el-button>
        <el-button :loading="queryLoading" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button type="primary" @click="handleOpenAddDialog(null)">新增</el-button>
        <upload-temu-sku class="mb-3" style="float: right" />
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <!--平台/站点/店铺-->
      <vxe-column field="platformSiteShop" title="平台/站点/店铺">
        <template #default="{row}">
          <span>{{ generateString(row) }}</span>
        </template>
      </vxe-column>
      <vxe-column field="temuSkuId" title="Temu SKU ID" />
      <vxe-column field="omsSku" title="SKU" />
      <vxe-column field="createTime" title="创建时间" />
      <vxe-column field="updateTime" title="更新时间" />
      <vxe-column title="操作" width="120">
        <template v-slot="{row}">
          <el-button type="text" @click="handleOpenAddDialog(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" @update="handlePagerUpdate" />
    <add-dialog :current-row="currentRow" :platform-site-shop-options="platformSiteShopOptions" :visible.sync="openDialogVisible" @refresh="handleQuery" />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import { deleteRecord, getList } from './api'
import AddDialog from './AddDialog.vue'
import { getUserPlatformSiteShop } from '@/api/select'
import UploadTemuSku from './components/UploadTemuSku.vue'
export default {
  name: 'TemuSku',
  components: {
    AddDialog,
    Paging,
    UploadTemuSku
  },
  data() {
    return {
      maxHeight: 0,
      queryLoading: false,
      tableData: [],
      platformSiteShopOptions: [],
      siteList: [],
      shopList: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
        channels: [],
        temuSkuId: '',
        omsSku: ''
      },
      rules: {
        channels: [{ required: true, message: '请选择平台/站点/店铺', trigger: 'change' }]
      },
      currentRow: null,
      openDialogVisible: false
    }
  },
  computed: {
    queryParams() {
      const { channels } = this.form
      return {
        ...this.form,
        channels: channels.reduce((acc, current) => {
          const [platform, site, shop] = current
          acc.push({ platform, site, shop })
          return acc
        }, []),
        pageNo: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.fetchPlatformSiteShopOptions()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    generateString(row) {
      const parts = []

      if (row.platform) {
        parts.push(row.platform)
      }
      if (row.site) {
        parts.push(row.site)
      }
      if (row.shop) {
        parts.push(row.shop)
      }

      return parts.join('/')
    },
    async fetchPlatformSiteShopOptions() {
      const { datas } = await getUserPlatformSiteShop()
      this.platformSiteShopOptions = datas || []
    },
    async handleOpenAddDialog(row) {
      this.currentRow = row
      this.openDialogVisible = true
    },
    async handleDelete(row) {
      this.$confirm('确定删除吗', '提示', {
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.cancelButtonLoading = false
            const { msg } = await deleteRecord({ id: row.id }).finally(() => {
              instance.confirmButtonLoading = false
              instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            done()
            await this.handleQuery()
          }
          done()
        }
      }).catch(() => {

      })
    },
    handleQuery() {
      this.$refs.form.validate(async valid => {
        if (!valid) return
        if (this.controller) {
          this.controller.abort()
          this.controller = null
          await this.handleQuery()
          return
        }
        this.queryLoading = true
        this.controller = new AbortController()
        const signal = this.controller.signal
        if (this.queryChange) {
          this.pager.current = 1
        }
        const { datas } = await getList(this.queryParams, signal).finally(() => {
          this.queryLoading = this.queryChange = false
        })
        this.tableData = datas?.records || []
        this.pager.total = datas?.pager?.total || 0
      })
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.handleQuery()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
